import store from '@/store';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import { ref, watch } from '@vue/composition-api';

export default function useTransportsList() {
    const transportListTable = ref([]);

    const transportTableColumns = [
        { text: i18n.t('Name'), value: 'name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Type'), value: 'type', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('TotalCapacity'), value: 'totalCapacity', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('HandicappedCapacity'), value: 'handicappedCapacity', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('StandingCapacity'), value: 'standingCapacity', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('FreeSeats'), value: 'freeSeats', class: 'text-uppercase', width: '12rem' },

        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const searchQuery = ref('');
    const totalTransportListTable = ref(transportListTable.length);
    const loading = ref(false);
    const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
    });
    const oldSearch = ref('');
    const oldTotalCapacityFilter = ref([0, 100]);
    const oldHandicappedCapacityFilter = ref([0, 100]);
    const oldStandingCapacityFilter = ref([0, 100]);
    const oldFreeSeatsFilter = ref([0, 100]);
    const oldTypeFilter = ref('');

    //fetch data
    const fetchTransportList = () => {
        let verifyQuery = store.getters['app-transport/getTransportsQuery'];
        let isDeleteTransport = store.getters['app-transport/getIsDeleteTransport'];

        store
            .dispatch('app-transport/fetchTransportList')
            .then((response) => {
                if (isDeleteTransport) {
                    transportListTable.value = [];
                    transportListTable.value.push(...response.data.data);
                    store.commit('app-transport/setIsDeleteTransport', false);
                } else if (
                    oldSearch.value == verifyQuery.filters.$or[0].name.$containsi &&
                    // oldTotalCapacityFilter.value[0] == verifyQuery.filters.totalCapacity.$between[0] &&
                    // oldTotalCapacityFilter.value[1] == verifyQuery.filters.totalCapacity.$between[1] &&
                    // oldHandicappedCapacityFilter.value[0] == verifyQuery.filters.handicappedCapacity.$between[0] &&
                    // oldHandicappedCapacityFilter.value[1] == verifyQuery.filters.handicappedCapacity.$between[1] &&
                    // oldStandingCapacityFilter.value[0] == verifyQuery.filters.standingCapacity.$between[0] &&
                    // oldStandingCapacityFilter.value[1] == verifyQuery.filters.standingCapacity.$between[1] &&
                    // oldFreeSeatsFilter.value[0] == verifyQuery.filters.freeSeats.$between[0] &&
                    // oldFreeSeatsFilter.value[1] == verifyQuery.filters.freeSeats.$between[1] &&
                    oldTypeFilter.value == verifyQuery.filters.type.$containsi
                ) {
                    /**
                     * avoid duplicate data when filtering
                     */
                    //transport data from query
                    let dataFromQuery = response.data.data;
                    //merge transport data from query with transportListTable.value
                    let updatedTransportList = [...transportListTable.value, ...dataFromQuery];
                    //remove duplicates from updatedTransportList
                    const _transportListTable = Array.from(
                        new Set(updatedTransportList.map((transport) => transport.id)),
                    ).map((id) => updatedTransportList.find((transport) => transport.id === id));
                    //update transportListTable.value
                    transportListTable.value = _transportListTable;
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].name.$containsi;
                    // oldTotalCapacityFilter.value = verifyQuery.filters.totalCapacity.$between;
                    // oldHandicappedCapacityFilter.value = verifyQuery.filters.handicappedCapacity.$between;
                    // oldStandingCapacityFilter.value = verifyQuery.filters.standingCapacity.$between;
                    // oldFreeSeatsFilter.value = verifyQuery.filters.freeSeats.$between;
                    oldTypeFilter.value = verifyQuery.filters.type.$containsi;
                    transportListTable.value = [];
                    transportListTable.value.push(...response.data.data);
                }

                totalTransportListTable.value = response.data.meta.pagination.total;
                // parkTotalLocal.value = parkTotal;1

                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    watch([searchQuery, options], () => {
        loading.value = true;
        fetchTransportList();
    });

    return {
        options,
        searchQuery,
        loading,
        transportTableColumns,
        transportListTable,
        totalTransportListTable,
        fetchTransportList,
    };
}
