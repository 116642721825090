<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
                    <v-container>
                        <v-tabs center-active>
                            <v-tab>{{ $t('TripProfile') }}</v-tab>
                            <v-tab :disabled="isLoading">{{ $t('Transports') }}</v-tab>
                            <v-tab :disabled="isLoading">{{ $t('Routes') }}</v-tab>
                            <v-tab-item>
                                <v-row class="mt-10">
                                    <v-col cols="12" md="12">
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider :name="$t('Headsign')" v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :label="$t('Headsign')"
                                                        filled
                                                        hide-details="auto"
                                                        name="headsign"
                                                        v-model="form.headsign"
                                                        shaped></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider :name="$t('ShortName')" v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        filled
                                                        shaped
                                                        :label="$t('ShortName')"
                                                        name="short_name"
                                                        hide-details="auto"
                                                        v-model="form.short_name"></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="4">
                                                <ValidationProvider :name="$t('Direction')" v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="directionType"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.direction"
                                                        :label="$t('Direction')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$t('WheelchairAcessible')"
                                                    v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="wheelchairType"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.wheelchair_accessible"
                                                        :label="$t('WheelchairAcessible')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="4">
                                                <ValidationProvider :name="$t('BikesAllowed')" v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="bikeType"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.bikes_allowed"
                                                        :label="$t('BikesAllowed')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <div class="d-flex mt-8">
                                    <v-text-field
                                        expanded
                                        v-model="searchTransportsQuery"
                                        @change="searchTransports()"
                                        :placeholder="$t('Search')"
                                        outlined
                                        hide-details
                                        dense
                                        :prepend-inner-icon="mdiMagnify"
                                        @keydown.enter.prevent="searchTransports"
                                        class="user-search me-3 mb-4">
                                    </v-text-field>
                                </div>
                                <ValidationProvider
                                    :name="$t('Transports')"
                                    rules="required"
                                    v-slot="{ errors }"
                                    ref="transports-table">
                                    <v-data-table
                                        show-select
                                        calculate-widths
                                        :items-per-page="5"
                                        :footer-props="{
                                            'items-per-page-options': [5, 10, 15, 20],
                                            'items-per-page-text': $t('RowsPerPage'),
                                        }"
                                        :header-props="{ 'sort-by-text': $t('SortBy') }"
                                        :headers="transportTableColumns"
                                        :items="form.transport.length > 0 ? orderBySelectedTrnsport : transports"
                                        :server-items-length="totalTransportListTable"
                                        :loading="isLoading"
                                        single-select
                                        v-model="form.transport"
                                        name="transports"
                                        required
                                        :options.sync="transportOptions"
                                        @update:page="paginationHandle"
                                        height="500"
                                        fixed-header>
                                        <template #[`item.name`]="{ item }">
                                            <span class="font-weight-medium text--primary">{{ item.name }}</span>
                                        </template>
                                    </v-data-table>
                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </v-tab-item>
                            <v-tab-item>
                                <div class="d-flex mt-8">
                                    <v-text-field
                                        expanded
                                        v-model="searchRoutesQuery"
                                        @change="searchRoutes()"
                                        :placeholder="$t('Search')"
                                        outlined
                                        hide-details
                                        dense
                                        :prepend-inner-icon="mdiMagnify"
                                        @keydown.enter.prevent="searchRoutes"
                                        class="user-search me-3 mb-4">
                                    </v-text-field>
                                </div>
                                <ValidationProvider
                                    :name="$t('Routes')"
                                    rules="required"
                                    v-slot="{ errors }"
                                    ref="routes">
                                    <v-data-table
                                        show-select
                                        calculate-widths
                                        :items-per-page="5"
                                        :footer-props="{
                                            'items-per-page-options': [5, 10, 15, 20],
                                            'items-per-page-text': $t('RowsPerPage'),
                                        }"
                                        :header-props="{ 'sort-by-text': $t('SortBy') }"
                                        :headers="tableColumns"
                                        :items="form.route.length > 0 ? orderBySelectedRoute : routeListTable"
                                        :server-items-length="totalRouteListTable"
                                        v-model="form.route"
                                        single-select
                                        name="routes"
                                        required
                                        :options.sync="routeOptions"
                                        @update:page="paginationHandle"
                                        height="500"
                                        fixed-header>
                                    </v-data-table>
                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </v-tab-item>
                        </v-tabs>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="goBack" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="!addTrip">
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('TripProfile') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('TripProfile') }}
                        </p>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('AddTrip') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('AddTrip') }}
                        </p>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <div v-if="!addTrip">
                        <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="ehite">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                        </v-btn>
                        <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                        </v-btn>
                        <v-btn v-show="isDisabled" @click="editInformation" class="mx-3" color="grey-light">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                        </v-btn>

                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                <v-card>
                                    <v-card-text class="text-center">
                                        <p class="title font-weight-semibold black--text mt-12">
                                            {{ $t('Alerts.Sure') }}
                                        </p>
                                    </v-card-text>
                                    <v-card-text class="text-center">
                                        <p class="body-2">
                                            {{ $t('Alerts.SureDeleteTrip') }}
                                            <strong class="black--text">{{ form.short_name }}</strong> ?<br />
                                            {{ $t('Alerts.Irreversible') }}
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end mt-4">
                                        <v-btn @click="deleteTrip" :loading="isLoading" color="error" class="px-5">{{
                                            $t('Buttons.Delete')
                                        }}</v-btn>
                                        <v-btn text @click="dialog.value = false" :loading="isLoading">{{
                                            $t('Buttons.Cancel')
                                        }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.AddTripSueccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="updateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.UpdateTripSueccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="deleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.DeleteTripSueccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>

<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
    } from '@mdi/js';
    import _ from 'lodash';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { getAgencys } from '@/api/agency.js';
    import useTransportsList from '@/views/transports/transports-list/useTransportsList';
    import { getTransports } from '@/api/transports.js';
    import useRoutesList from '@/views/route/routes-list/useRoutesList';

    const initialState = () => ({
        isLoading: false,
        form: {
            route: [],
            headsign: '',
            short_name: '',
            direction: null,
            block_id: '',
            wheelchair_accessible: null,
            bikes_allowed: null,
            stop_times: [],
            frequencies: [],
            attribution: '',
            calendars: [],
            calendar_dates: [],
            shape: '',
            transport: [],
        },

        isLoadingTable: true,
    });

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,

                addTrip: false,

                isError: false,
                createSuccess: false,
                updateSuccess: false,
                deleteSuccess: false,

                directionType: [
                    { name: 'DirectionOutbound', value: 'O0' },
                    { name: 'DirectionInbound', value: 'O1' },
                ],

                wheelchairType: [
                    { name: 'NoInfoWheelchair', value: 'O0' },
                    { name: 'WheelchairAllowedInfo', value: 'O1' },
                    { name: 'WheelchairNotAllowedInfo', value: 'O2' },
                ],

                bikeType: [
                    { name: 'NoInfoBikes', value: 'O0' },
                    { name: 'BikesAllowedInfo', value: 'O1' },
                    { name: 'BikesNotAllowedInfo', value: 'O2' },
                ],

                transports: [],
                searchRoutesQuery: null,
                searchTransportsQuery: null,
                routeQuery: {},
                transportQuery: {},
                routeOptions: {
                    page: 1,
                },
                transportOptions: {
                    page: 1,
                },
                transportPage: 1,
                totalTransportListTable: 0,
            };
        },

        setup() {
            const { transportTableColumns, transprtListTable } = useTransportsList();
            const { tableColumns, routeListTable, totalRouteListTable, fetchRoutesList } = useRoutesList();

            return {
                transportTableColumns,
                transprtListTable,

                tableColumns,
                routeListTable,
                totalRouteListTable,
                fetchRoutesList,

                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,
            };
        },
        created() {
            getAgencys()
                .then((response) => {
                    this.agencys = response.data.data;
                })
                .catch((error) => {
                    this.isError = true;
                });

            this.routeQuery = this.$store.getters['app-routes/getRoutesQuery'];
            this.transportQuery = this.$store.getters['app-transports/getTransportsQuery'];
            this.getTransportList();
            this.fetchRoutesList();

            for (let index = 0; index < this.directionType.length; index++) {
                this.directionType[index].name = this.$t(this.directionType[index].name);
            }
            for (let index = 0; index < this.wheelchairType.length; index++) {
                this.wheelchairType[index].name = this.$t(this.wheelchairType[index].name);
            }
            for (let index = 0; index < this.bikeType.length; index++) {
                this.bikeType[index].name = this.$t(this.bikeType[index].name);
            }

            if (this.$route.params.id != 'add') {
                this.addTrip = false;
                this.isDisabled = true;
                this.getTrip();
            } else {
                this.addTrip = true;
                this.isDisabled = false;
            }
        },

        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },

            getTrip() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-routes/fetchTrip', this.$route.params.id)
                    .then((response) => {
                        this.isLoading = false;

                        this.form = response.data.data;
                        this.form.transport = [response.data.data.transport];
                        this.form.route = [response.data.data.route];
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            async onSubmit() {
                if (this.addTrip) {
                    await this.onAddTrip();
                } else await this.onUpdateTrip();

                setTimeout(() => {
                    this.$router.push({ name: 'RoutesList', params: { tab: 1, tripTab: 0 } });
                }, 1500);
            },

            async onAddTrip() {
                this.isLoading = true;

                this.form.route = this.form.route.map((item) => {
                    return item.id;
                });

                this.form.transport = this.form.transport.map((item) => {
                    return item.id;
                });

                await this.$store
                    .dispatch('app-routes/addTrip', this.form)
                    .then(async (response) => {
                        this.createSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            async onUpdateTrip() {
                this.isLoading = true;

                this.form.route = this.form.route.map((item) => {
                    return item.id;
                });

                this.form.transport = this.form.transport.map((item) => {
                    return item.id;
                });

                await this.$store
                    .dispatch('app-routes/updateTrip', this.form)
                    .then(async (response) => {
                        this.updateSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            deleteTrip() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-routes/deleteTrip', this.$route.params.id)
                    .then((response) => {
                        this.deleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'RoutesList', params: { tab: 1, tripTab: 0 } });
                        }, 1500);
                    })
                    .catch((error) => {
                        this.isError = true;
                    });
            },

            async getTransportList() {
                await getTransports({
                    sort: ['name:asc'],
                    filters: {
                        $or: [
                            {
                                name: {
                                    $containsi: this.searchTransportsQuery,
                                },
                            },
                        ],
                    },
                    pagination: {
                        page: this.transportPage,
                    },
                })
                    .then((response) => {
                        this.transports.push(...response.data.data);
                        this.totalTransportListTable = response.data.meta.pagination.total;
                    })
                    .catch((error) => {});
            },

            searchRoutes() {
                this.isLoading = true;
                this.$store.commit('app-routes/setRouteSearch', this.searchRoutesQuery);
                this.routeQuery = this.$store.getters['app-routes/getRoutesQuery'];
                this.routeQuery.pagination.page = 1;
                this.fetchRoutesList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },
            searchTransports() {
                this.isLoading = true;
                this.transportQuery = this.$store.getters['app-transports/getTransportsQuery'];
                this.transportQuery.pagination.page = 1;
                this.getTransportList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            paginationHandle(e) {
                if (this.routeOptions.page > this.routeQuery.pagination.page) {
                    this.routeQuery.pagination.page = this.routeOptions.page;
                    this.$store.commit('app-routes/setRoutesQuery', this.routeQuery);
                    this.fetchRoutesList();
                }

                if (this.transportOptions.page > this.transportPage) {
                    this.getTransportList();
                }
            },

            goBack() {
                this.$router.push({ name: 'RoutesList', params: { tab: 1, tripTab: 0 } });
            },
        },

        computed: {
            orderBySelectedRoute() {
                if (this.form.route.length > 0 && this.form.route[0] != null) {
                    this.routeListTable.unshift(this.form.route[0]);

                    this.routeListTable = this.routeListTable.filter(
                        (route, index, self) => index === self.findIndex((i) => i.id === route.id),
                    );
                }

                return this.routeListTable;
            },
            orderBySelectedTrnsport() {
                if (this.form.transport.length > 0 && this.form.transport[0] != null) {
                    this.transports.unshift(this.form.transport[0]);

                    this.transports = this.transports.filter(
                        (transport, index, self) => index === self.findIndex((i) => i.id === transport.id),
                    );
                }

                return this.transports;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
