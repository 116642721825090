import store from '@/store';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import { ref, watch } from '@vue/composition-api';

export default function useRoutesList() {
    const routeListTable = ref([]);

    const tableColumns = [
        { text: i18n.t('Name'), value: 'long_name', class: 'text-uppercase', width: '12rem' },
        // { text: i18n.t('Agency'), value: 'agency.name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Description'), value: 'desc', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Type'), value: 'type', class: 'text-uppercase', width: '12rem' },

        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const totalRouteListTable = ref(routeListTable.length);
    const oldSearch = ref('');
    const oldTypeFilter = ref('');
    const oldAgencyFilter = ref('');
    const oldFareFilter = ref('');
    const loading = ref(false);

    const fetchRoutesList = () => {
        let verifyQuery = store.getters['app-routes/getRoutesQuery'];
        let isDeleteRoute = store.getters['app-routes/getIsDeleteRoute'];
        store
            .dispatch('app-routes/fetchRouteList', {})
            .then((response) => {
                console.log(response);
                if (isDeleteRoute) {
                    routeListTable.value = [];
                    routeListTable.value.push(...response.data.data);
                    store.commit('app-routes/setIsDeleteRoute', false);
                } else if (
                    oldSearch.value == verifyQuery.filters.$or[0].long_name.$containsi &&
                    oldTypeFilter.value == verifyQuery.filters.type.$containsi
                    // oldAgencyFilter.value == verifyQuery.filters.agency.name.$containsi &&
                    // oldFareFilter.value == verifyQuery.filters.mobility_fare_rules.fare.price.$between
                ) {
                    routeListTable.value.push(...response.data.data);
                    routeListTable.value = _.uniqBy(routeListTable.value, 'id');
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].long_name.$containsi;
                    oldTypeFilter.value = verifyQuery.filters.type.$containsi;
                    // oldAgencyFilter.value = verifyQuery.filters.agency.name.$containsi;
                    // oldFareFilter.value = verifyQuery.filters.mobility_fare_rules.fare.price.$between;

                    routeListTable.value = [];
                    routeListTable.value.push(...response.data.data);

                    //remove duplicate from array
                    routeListTable.value = _.uniqBy(routeListTable.value, 'id');
                }

                totalRouteListTable.value = response.data.meta.pagination.total;
                // parkTotalLocal.value = parkTotal;1

                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    return {
        tableColumns,
        routeListTable,
        totalRouteListTable,
        fetchRoutesList,
    };
}
